var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              treeTitle: "组织架构",
              isShowdig: false,
              showCheckbox: false,
              expandOnClickNode: false,
              treeExpand: false,
              currentNodeKey: _vm.currentNodeKey,
              defaultExpandedKeys: _vm.defaultExpandedKeys,
              nodeKey: "id",
              defaultCheckedKeys: _vm.defaultCheckedKeys,
            },
            on: { getNodeClick: _vm.treeNodeClick },
          }),
          _c(
            "el-main",
            {
              staticStyle: {
                position: "relative",
                height: "calc(100vh - 94px)",
              },
            },
            [
              _c("head-layout", {
                staticClass: "head-section",
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": "境外项目人员信息",
                },
                on: {
                  "head-edit": _vm.headEdit,
                  "head-save": _vm.headSave,
                  "head-submit": _vm.headSubmit,
                  "head-summary": _vm.headSummary,
                  "head-reportView": _vm.headReportView,
                },
              }),
              _c(
                "div",
                { staticClass: "head-picker" },
                [
                  _c("el-date-picker", {
                    staticClass: "searchInput",
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      size: "mini",
                      disabled: _vm.type === "workflow",
                    },
                    on: { change: _vm.chooseDate },
                    model: {
                      value: _vm.query.formDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "formDate", $$v)
                      },
                      expression: "query.formDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { padding: "0 12px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.dataForm,
                        rules: _vm.rules,
                        "label-width": "200px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "组织名称", prop: "orgName" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.dataForm.orgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "orgName", $$v)
                                      },
                                      expression: "dataForm.orgName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "填报日期",
                                    prop: "updateTime",
                                  },
                                },
                                [
                                  _c("template", { slot: "label" }, [
                                    _c("span", [_vm._v("填报日期:")]),
                                  ]),
                                  _c("el-date-picker", {
                                    attrs: {
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      type: "date",
                                      disabled: "true",
                                      placeholder: "保存后自动生成",
                                    },
                                    model: {
                                      value: _vm.dataForm.updateTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "updateTime",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.updateTime",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "数据状态",
                                    prop: "dataState",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.dataForm.dataState,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "dataState",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.dataState",
                                      },
                                    },
                                    _vm._l(_vm.dataStateDic, function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "在册中方职工数",
                                    prop: "regeditedChineseCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "8",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.dataForm.regeditedChineseCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "regeditedChineseCnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataForm.regeditedChineseCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.regeditedChineseCntS <
                                            0 ||
                                          _vm.dataForm.regeditedChineseCntS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm.regeditedChineseCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "劳务公司派遣中方员工数",
                                    prop: "dispatchedChineseCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "8",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.dataForm.dispatchedChineseCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "dispatchedChineseCnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataForm.dispatchedChineseCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.dispatchedChineseCntS <
                                            0 ||
                                          _vm.dataForm.dispatchedChineseCntS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm
                                                .dispatchedChineseCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "第三方中方技术服务人员数",
                                    prop: "thirdPartyChineseCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "8",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.dataForm.thirdPartyChineseCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "thirdPartyChineseCnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataForm.thirdPartyChineseCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.thirdPartyChineseCntS <
                                            0 ||
                                          _vm.dataForm.thirdPartyChineseCntS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm
                                                .thirdPartyChineseCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "分包商（含劳务）人数",
                                    prop: "subcontractorChineseCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "8",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value:
                                        _vm.dataForm.subcontractorChineseCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "subcontractorChineseCnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataForm.subcontractorChineseCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm
                                            .subcontractorChineseCntS < 0 ||
                                          _vm.dataForm
                                            .subcontractorChineseCntS == null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm
                                                .subcontractorChineseCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "在册外籍职工数",
                                    prop: "regularForeignerCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "8",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.dataForm.regularForeignerCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "regularForeignerCnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dataForm.regularForeignerCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.regularForeignerCntS <
                                            0 ||
                                          _vm.dataForm.regularForeignerCntS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm.regularForeignerCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "其他外籍人员数",
                                    prop: "otherForeignerCnt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "controls-position": "right",
                                      disabled: _vm.state,
                                      maxlength: "8",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.dataForm.otherForeignerCnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "otherForeignerCnt",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.otherForeignerCnt",
                                    },
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.otherForeignerCntS < 0 ||
                                          _vm.dataForm.otherForeignerCntS ==
                                            null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm.otherForeignerCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "align-items": "center" },
                                  attrs: { label: "项目总人数" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "0 !important",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.totalCount))]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.totalCountS < 0 ||
                                          _vm.dataForm.totalCountS == null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm.totalCountS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "align-items": "center" },
                                  attrs: { label: "中方员工总人数" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "0 !important",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.chineseCnt))]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.chineseCntS < 0 ||
                                          _vm.dataForm.chineseCntS == null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm.chineseCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "align-items": "center" },
                                  attrs: { label: "外籍人员总人数" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "0 !important",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.foreignerCnt))]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "light",
                                        content:
                                          _vm.dataForm.foreignerCntS < 0 ||
                                          _vm.dataForm.foreignerCntS == null ||
                                          false
                                            ? "上次填报数据：0"
                                            : "上次填报数据：" +
                                              _vm.dataForm.foreignerCntS,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning-outline iconLine",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("head-layout", {
                attrs: {
                  "head-title": "下级组织项目人员信息",
                  "head-btn-options": _vm.subordinateBtn,
                },
                on: {
                  "head-reject": _vm.reject,
                  "head-urge-processing": _vm.headUrging,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                staticClass: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                class: _vm.type != "workflow" ? "overseasProjectSummary" : "",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  "cell-style": _vm.cellStyle,
                },
                on: {
                  "gird-handle-select-click": _vm.selectionChange,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "grid-row-detail-click": _vm.rowView,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return undefined
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.overseasProjectFilling
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
              attrs: {
                title: "境外项目人员信息编辑",
                "modal-append-to-body": false,
                "close-on-click-modal": false,
                visible: _vm.overseasProjectFilling,
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.overseasProjectFilling = $event
                },
                close: _vm.overseasDialog,
              },
            },
            [
              _c("overseasProjectFillingEdit", {
                ref: "overseasProjectFillingEdit",
                attrs: { rowData: _vm.rowData },
                on: { overseasDialog: _vm.overseasDialog },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }