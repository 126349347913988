<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        treeTitle="组织架构"
        :isShowdig="false"
        :showCheckbox="false"
        :expandOnClickNode="false"
        :treeExpand="false"
        :currentNodeKey="currentNodeKey"
        :defaultExpandedKeys="defaultExpandedKeys"
        :nodeKey="'id'"
        :defaultCheckedKeys="defaultCheckedKeys"
        @getNodeClick="treeNodeClick"
      />
      <el-main style="position: relative;height: calc(100vh - 94px)">
        <head-layout
          class="head-section"
          :head-btn-options="headBtnOptions"
          head-title="境外项目人员信息"
          @head-edit="headEdit"
          @head-save="headSave"
          @head-submit="headSubmit"
          @head-summary="headSummary"
          @head-reportView="headReportView"
        ></head-layout>
        <div class="head-picker">
          <el-date-picker
            v-model="query.formDate"
            type="date"
            class="searchInput"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 200px"
            size="mini"
            @change="chooseDate"
            :disabled="type === 'workflow'"
          />
        </div>
        <div style="padding: 0 12px">
          <el-form :model="dataForm" :rules="rules" ref="dataForm" label-width="200px" class="demo-ruleForm">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="组织名称" prop="orgName">
                  <el-input
                    v-model="dataForm.orgName"
                    controls-position="right"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="填报日期" prop="updateTime">
                  <template slot="label">
                    <span>填报日期:</span>
                  </template>
                  <el-date-picker
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="dataForm.updateTime"
                    type="date"
                    disabled="true"
                    placeholder="保存后自动生成"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="数据状态" prop="dataState">
                  <el-select v-model="dataForm.dataState" disabled placeholder="请选择">
                    <el-option
                      v-for="item in dataStateDic"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="在册中方职工数" prop="regeditedChineseCnt">
                  <el-input
                    v-model="dataForm.regeditedChineseCnt"
                    controls-position="right"
                    :disabled="state"
                    maxlength="8"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.regeditedChineseCntS<0 || dataForm.regeditedChineseCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.regeditedChineseCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="劳务公司派遣中方员工数" prop="dispatchedChineseCnt">
                  <el-input
                    v-model="dataForm.dispatchedChineseCnt"
                    controls-position="right"
                    :disabled="state"
                    maxlength="8"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.dispatchedChineseCntS<0 || dataForm.dispatchedChineseCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.dispatchedChineseCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="第三方中方技术服务人员数" prop="thirdPartyChineseCnt">
                  <el-input
                    v-model="dataForm.thirdPartyChineseCnt"
                    controls-position="right"
                    :disabled="state"
                    maxlength="8"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.thirdPartyChineseCntS<0 || dataForm.thirdPartyChineseCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.thirdPartyChineseCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="分包商（含劳务）人数" prop="subcontractorChineseCnt">
                  <el-input
                    v-model="dataForm.subcontractorChineseCnt"
                    controls-position="right"
                    :disabled="state"
                    maxlength="8"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.subcontractorChineseCntS<0 || dataForm.subcontractorChineseCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.subcontractorChineseCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="在册外籍职工数" prop="regularForeignerCnt">
                  <el-input
                    v-model="dataForm.regularForeignerCnt"
                    controls-position="right"
                    :disabled="state"
                    maxlength="8"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.regularForeignerCntS<0 || dataForm.regularForeignerCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.regularForeignerCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="其他外籍人员数" prop="otherForeignerCnt">
                  <el-input
                    v-model="dataForm.otherForeignerCnt"
                    controls-position="right"
                    :disabled="state"
                    maxlength="8"
                    show-word-limit

                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.otherForeignerCntS<0 || dataForm.otherForeignerCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.otherForeignerCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="项目总人数" style="align-items: center;">
                  <div style="margin-left: 0 !important;">{{ totalCount }}</div>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.totalCountS<0 || dataForm.totalCountS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.totalCountS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="中方员工总人数" style="align-items: center;">
                  <div style="margin-left: 0 !important;">{{ chineseCnt }}</div>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.chineseCntS<0 || dataForm.chineseCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.chineseCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="外籍人员总人数" style="align-items: center;">
                  <div style="margin-left: 0 !important;">{{ foreignerCnt }}</div>
                  <el-tooltip class="item" effect="light"
                              :content="dataForm.foreignerCntS<0 || dataForm.foreignerCntS==null || false?'上次填报数据：0':'上次填报数据：' + dataForm.foreignerCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <head-layout
          head-title="下级组织项目人员信息"
          :head-btn-options="subordinateBtn"
          @head-reject="reject"
          @head-urge-processing="headUrging"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          class="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :class="type != 'workflow'?'overseasProjectSummary':''"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @gird-handle-select-click="selectionChange"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowView"
          :cell-style="cellStyle"
        >
          <template #customBtn="{row}">
            <!--            <el-button-->
            <!--              type="text"-->
            <!--              v-if="row.dataState === '4' && (dataForm.dataState === '1' || dataForm.dataState === '3')"-->
            <!--              @click="rowEdit(row)"-->
            <!--            > 编辑-->
            <!--            </el-button>-->
            <!--            <el-button-->
            <!--              type="text"-->
            <!--              v-if="row.dataState === '4' && (dataForm.dataState === '1' || dataForm.dataState === '3')"-->
            <!--              @click="reject(row)"-->
            <!--            > 驳回-->
            <!--            </el-button>-->
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog
      v-dialog-drag
      :title="'境外项目人员信息编辑'"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="overseasProjectFilling"
      v-if="overseasProjectFilling"
      width="80%"
      @close="overseasDialog"
    >
      <overseasProjectFillingEdit ref="overseasProjectFillingEdit" :rowData="rowData"
                                  @overseasDialog="overseasDialog"></overseasProjectFillingEdit>
    </el-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import CommonTree from "@/views/components/com_tree";
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {
  getDetail, submitSummary, summaryGetData,
  summaryClick,
  summaryGetPage, reportTree, getDataStatus, summaryClickDataState, rejectSendingMessage
} from "@/api/foreignReport/domesticReport";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {detail as getProcessDetail, detail, processList as getProcessList} from "@/api/plugin/workflow/process";
import Template from "@/views/message/template/list.vue";
import {dictionaryBiz} from "@/api/reportTasks";
import {formatTime} from "@/util";
import overseasProjectFillingEdit
  from "@/views/business/reportManager/summaryReport/overseasProjectSummary/editDialog.vue";
import website from "@/config/website";
import {reviewAndUrging} from "@/api/reportReview";

export default {
  name: "dataSummary",
  components: {
    overseasProjectFillingEdit,
    Template,
    FormLayout,
    HeadLayout,
    GridLayout,
    CommonTree,
  },
  mixins: [exForm, draft],
  data() {
    return {
      whether: true,
      defaultExpandedKeys: [],
      currentNodeKey: '',
      treeShow: false,
      nodeId: '',
      fillDeptName: '',
      overseasProjectFilling: false,
      rowData: {},
      state: true,
      processTemplateKey: "report_overseas_summary",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      dataForm: {
        projectArea: "2",
        chineseCntS: "",//中方员工总数
        foreignerCntS: "",//外籍人员总人数
        totalCountS: "", //总人数
        otherForeignerCntS: "", //其他外籍人员数
        regularForeignerCntS: "", //在册外籍职工数
        subcontractorChineseCntS: "", //分包商中方员工数
        thirdPartyChineseCntS: "", //第三方中方技术服务人员数
        dispatchedChineseCntS: "", //劳务公司派遣中方员工数
        regeditedChineseCntS: "", //在册中方职工数
        chineseCnt: "",//中方员工总数
        foreignerCnt: "",//外籍人员总人数
        totalCount: "", //总人数
        otherForeignerCnt: "", //其他外籍人员数
        regularForeignerCnt: "", //在册外籍职工数
        subcontractorChineseCnt: "", //分包商中方员工数
        thirdPartyChineseCnt: "", //第三方中方技术服务人员数
        dispatchedChineseCnt: "", //劳务公司派遣中方员工数
        regeditedChineseCnt: "", //在册中方职工数
        statDate: "",//统计日期
        dataState: "",
        orgCode: "",
        dateSource: "2"
      },
      rules: {
        orgName: [
          {required: true, message: '请输入组织名称', trigger: ['blur']},
        ],
        regeditedChineseCnt: [
          {required: true, message: '请输入在册中方职工数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        dispatchedChineseCnt: [
          {required: true, message: '请输入劳务公司派遣中方员工数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        thirdPartyChineseCnt: [
          {required: true, message: '请输入第三方中方技术服务人员数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        subcontractorChineseCnt: [
          {required: true, message: '请输入分包商（含劳务）人数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        regularForeignerCnt: [
          {required: true, message: '请输入在册外籍职工', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        otherForeignerCnt: [
          {required: true, message: '请输入其他外籍人员数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
      },
      dataState: '',
      dicData: [],
      dataStateDic: [],
      treeData: [],
      defaultProps: {
        label: "deptName",
        value: "deptName",
        key: "id",
      },
      query: {
        affiliationDept: "",
        projectArea: "2",
        templateCode: "overseasCode",//境外编码
        formDate: ''
      },
      deptId: '',
      defaultCheckedKeys: [],
      searchTitle: "title",
      paperPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      saveLoading: false,
      tableLoading: false,
      type: undefined,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
    }
  },
  watch: {
    defaultCheckedKeys: {
      // immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // tree树结构点击会加上下面这个类名
            // 如果默认全部展开-那就会关闭
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    refresh() {
      return this.$store.state.common.report
    },
    chineseCnt() {
      let number = 0;
      if (this.dataForm.regeditedChineseCnt || this.dataForm.dispatchedChineseCnt || this.dataForm.thirdPartyChineseCnt || this.dataForm.subcontractorChineseCnt) {
        number = (this.dataForm.regeditedChineseCnt != undefined ? Number(this.dataForm.regeditedChineseCnt) : 0)
          + (this.dataForm.dispatchedChineseCnt != undefined ? Number(this.dataForm.dispatchedChineseCnt) : 0)
          + (this.dataForm.thirdPartyChineseCnt != undefined ? Number(this.dataForm.thirdPartyChineseCnt) : 0)
          + (this.dataForm.subcontractorChineseCnt != undefined ? Number(this.dataForm.subcontractorChineseCnt) : 0)
      } else {
        number = '';
      }
      this.dataForm.chineseCnt = number;
      return number;
    },
    foreignerCnt() {
      let number = 0;
      if (this.dataForm.otherForeignerCnt || this.dataForm.regularForeignerCnt) {
        number = (this.dataForm.otherForeignerCnt != undefined ? Number(this.dataForm.otherForeignerCnt) : 0) + (this.dataForm.regularForeignerCnt != undefined ? Number(this.dataForm.regularForeignerCnt) : 0);
      } else {
        number = '';
      }
      this.dataForm.foreignerCnt = number;
      return number;
    },
    totalCount() {
      let number = 0;
      if (this.chineseCnt || this.foreignerCnt) {
        number = (this.chineseCnt != undefined ? Number(this.chineseCnt) : 0)
          + (this.foreignerCnt != undefined ? Number(this.foreignerCnt) : 0);
      } else {
        number = '';
      }
      this.dataForm.totalCount = number;
      return number;
    },
    headBtnOptions() {
      let result = [];
      if (this.whether) {
        if (this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined || this.dataForm.dataState == "3") {
          result.push({
            label: '汇总',
            emit: "head-summary",
            type: "primary",
            icon: "el-icon-check",
            loading: this.saveLoading,
          })
        }
        if (this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined || this.dataForm.dataState == "3") {
          if (!this.state) {
            result.push({
              label: '保存',
              emit: "head-save",
              type: "primary",
              icon: "el-icon-check",
              loading: this.saveLoading,
            })
          } else {
            result.push({
              label: '修改',
              emit: "head-edit",
              type: "primary",
              icon: "el-icon-check",
              loading: this.saveLoading,
            })
          }
        }
        if (this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined || this.dataForm.dataState == "3") {
          result.push({
            label: '提交',
            emit: "head-submit",
            type: "primary",
            icon: "el-icon-check",
            loading: this.saveLoading,
          })
        }
        // if (!((this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined)) && this.dataForm.dataState != "3" && this.type != 'workflow') {
        //   result.push(
        //       {
        //         label: "流程详情",
        //         emit: "head-process_detail",
        //         type: "button",
        //         icon: "",
        //         btnOptType: "loseEfficacy",
        //       });
        // }
        // if (this.dataForm.dataState == "3" && this.type != 'workflow') {
        //   result.push(
        //     {
        //       label: "重新汇总",
        //       emit: "head-process_detail",
        //       type: "button",
        //       icon: "",
        //       btnOptType: "loseEfficacy",
        //     });
        // }
      }
      if (this.type != 'workflow') {
        result.push(
          {
            label: "报表查看",
            emit: "head-reportView",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });
      }

      return result
    },
    subordinateBtn() {
      let result = [];
      if (this.dataForm.dataState != "4") {
        result.push(
          {
            label: "退回重新填报",
            emit: "head-reject",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });

        result.push(
          {
            label: "催办",
            emit: "head-urge-processing",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });
      }
      return result;
    },
    searchColumns() {
      return [
        {
          label: "项目编号,项目名称",
          prop: "projectCode",
          span: 4,
          placeholder: "请输入项目编号或项目名称",
        },
        {
          label: "数据状态",
          prop: "dataState",
          type: "select",
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=data_status_report',
          dicData: [],
          placeholder: "请选择数据状态",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          span: 4,
        },
      ];
    },
    generateTopicsHeadOption() {
      return [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
    tableOptions() {
      return {
        selection: true,
        selectable: (row, index) => {
          return row.id != -1;
        },
        menu: false,
        linklabel: "projectName",
        column: [
          {
            label: "组织/项目名称",
            prop: 'projectName',
            align: "left",
            menuFixed: 'right',
            fixed: 'left',
            overHidden: true,
            width: 200,
          },
          {
            label: "填报状态",
            prop: "dataState",
            align: "center",
            type: "select",
            dataType: "string",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=data_status_report",
            fixed: 'left',
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 80,
          },
          // {
          //   label: "组织/项目编号",
          //   prop: 'projectCode',
          //   align: "left",
          //   overHidden: true,
          //   menuFixed: 'right',
          //   width: 200,
          // },
          {
            label: "统计日期",
            prop: "statDate",
            align: "center",
            menuFixed: 'right',
            width: 120,
            overHidden: true,
          },
          {
            label: "中方员工总数",
            prop: "chineseCnt",
            align: "left",
            width: 140,
            overHidden: true,
          },
          {
            label: "在册中方职工数",
            prop: "regeditedChineseCnt",
            align: "left",
            width: 140,
            overHidden: true,
          },
          {
            label: "劳务公司派遣中方员工数",
            prop: "dispatchedChineseCnt",
            align: "left",
            width: 140,
            overHidden: true,
          },
          {
            label: "第三方中方技术服务人员数",
            prop: "thirdPartyChineseCnt",
            align: "left",
            width: 140,
            overHidden: true,
          },
          {
            label: "分包商中方员工数",
            prop: "subcontractorChineseCnt",
            align: "left",
            width: 140,
            overHidden: true,
          },
          {
            label: "外籍人员总数",
            prop: "foreignerCnt",
            align: "left",
            width: 140,
            overHidden: true,
          },
          {
            label: "在册外籍职工数",
            prop: "regularForeignerCnt",
            align: "left",
            width: 140,
            overHidden: true,
          },
          {
            label: "其他外籍人员数",
            prop: "otherForeignerCnt",
            align: "left",
            width: 140,
            overHidden: true,
          },
          {
            label: "内部人员增减说明",
            prop: "remark1",
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "外部人员增减说明",
            prop: "remark2",
            align: "center",
            width: 140,
            overHidden: true,
          },
          {
            label: "数据来源",
            prop: "dateSource",
            align: "center",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=date_source",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 80,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            align: "center",
            width: 170,
            overHidden: true
          },
          {
            label: "创建人",
            prop: "createUserName",
            align: "center",
            width: 70,
            overHidden: true,
          },
          {
            label: "更新日期",
            prop: "updateTime",
            align: "center",
            width: 170,
            overHidden: true,
          },
          {
            label: "更新人",
            prop: "updateUserName",
            align: "center",
            width: 70,
            overHidden: true,
          },
        ],
      }
    },
  },
  methods: {
    restRefresh() {
      this.$store.commit('SET_REPORT', !this.refresh)
    },
    cellStyle({row, column, rowIndex, columnIndex}) {
      const value = row.dataState;
      if (value && column.property) {
        if (value == '1' && column.property == 'dataState') {
          return {color: '#e6a23c'};
        } else if (value == '2' && column.property == 'dataState') {
          return {color: '#409eff'};
        } else if (value == '3' && column.property == 'dataState') {
          return {color: '#f56c6c'};
        } else if (value == '4' && column.property == 'dataState') {
          return {color: '#67c23a'};
        } else {
          return {}; // 默认情况下不设置特定的背景色
        }
      }
    },
    // 查看流程详情
    headProcessDetail() {
      const processInsId = this.dataForm.processInsId;
      detail({processInsId: processInsId}).then((res) => {
        const {process} = res.data.data;
        const {id, taskId, processInstanceId, processId, processDefKey} =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey,
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl =
          window.location.origin + "#/process/external/iframe/detail?p=" + param + '&isNewWindow=true';
        window.open(sUrl, "_blank");
      });
    },
    receiveData(row) {
      let templateCode = "overseasCode";
      let type = "summary";
      this.$loading();
      reportTree(templateCode, type, row.fillDeptId, row.statDate)
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.treeData[0].id)
          })
          this.defaultExpandedKeys.push(row.fillDeptId)
          this.currentNodeKey = row.fillDeptId;
          this.nodeId = row.fillDeptId;
          this.fillDeptName = row.fillDeptName;
          this.dataForm.orgName = row.fillDeptName;
          this.query.unitCode = this.nodeId;
          this.query.affiliationDept = this.nodeId;
          this.query.formDate = row.statDate;
          this.dataForm.statDate = row.statDate
          this.summaryGetDataList()
          this.onLoad(this.page);
          this.treeShow = true;
        })
        .catch((err) => {
          this.$loading().close();
          this.treeLoading = false;
        }).finally(() => {
        this.$loading().close();
      });
    },
    initData() {
      if (this.treeData.length != 0) {
        this.defaultCheckedKeys.push(this.treeData[0].id)
      }
      this.treeShow = true
      // this.onLoad(this.page);
    },
    findDictValueByState() {
      if (this.dataForm && this.dataForm.dataState && Array.isArray(this.dicData)) {
        const item = this.dicData.find(item => item.dictKey === this.dataForm.dataState);
        return item ? item.dictValue : null;
      } else {
        return null;
      }
    },
    chooseDate(e) {
      this.state = true;
      if (this.query.formDate) {
        this.summaryGetDataList();
      } else {
        this.dataForm = [];
        this.dataForm.orgName = this.fillDeptName
      }
      this.onLoad(this.page)
    },
    summaryGetDataList() {
      let data = {
        formDate: this.query.formDate,
        orgCode: this.query.affiliationDept,
        projectArea: "2"
      }
      summaryGetData(data).then((res) => {
        if (res.data.code === 200) {
          this.dataForm = res.data.data
          this.dataState = this.findDictValueByState()
        }
        if (!res.data.data.dataState) {
          this.dataForm.dataState = "1"
        }
        if (!this.dataForm.orgName) {
          this.dataForm.orgName = this.fillDeptName
        }
      })
    },
    //提交
    headSubmit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.dataForm.id && this.dataForm.id != -1) {
            if (this.dataForm.statDate) {
              this.dataForm.templateCode = "overseasCode"
              this.dataForm.projectArea = "2"
              this.dataForm.formDate = this.dataForm.statDate
              this.dataForm.affiliationDept = this.dataForm.orgCode
              this.$loading()
              summaryClickDataState(this.dataForm).then(res => {
                if (res.data.code === 200) {
                  if (res.data.data) {
                    this.$loading().close()
                    this.$confirm("下级还有数据未通过，是否继续提交?", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning"
                    }).then(() => {
                      this.$confirm("确定将提交?", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                      })
                        .then(() => {
                          this.$loading();
                          let dataForm = this.dataForm
                          this.rpTaskAttributeSubmit(dataForm);
                        }).catch(() => {
                      })
                        .finally(() => {
                          this.$loading().close();
                        });
                    });
                  } else {
                    this.$loading().close()
                    this.$confirm("确定将提交?", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning"
                    })
                      .then(() => {
                        this.$loading();
                        let dataForm = this.dataForm
                        this.rpTaskAttributeSubmit(dataForm);
                      }).catch(() => {
                    })
                      .finally(() => {
                        this.$loading().close();
                      });
                  }
                }
              }).finally(() => {
                this.$loading().close();
              })
            } else {
              this.$message.warning("统计日期为空");
            }
          } else {
            this.$message.warning("请先保存数据");
          }
        } else {
          this.$message.warning("当前报表数据有误，请先汇总数据或修改数据！");
        }
      })
    },
    headSummary() {
      let dataList = []
      this.tableData.forEach(item => {
        if (item.dataState != "4" && item.dataState !== null && item.id != -1) {
          dataList.push(item);
        }
      })
      if (dataList.length !== 0) {
        this.$confirm("有任务未完成不能汇总！", {
          showCancelButton: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: "warning"
        })
      } else {
        if (this.dataForm.id == undefined || this.dataForm.id == -1) {
          if (this.query.formDate) {
            this.$loading()
            summaryClick(this.query).then(res => {
              if (res.data.code === 200 && res.data.data != "有任务未完成不能汇总,详情请通过搜索条件去确认未完成任务." && res.data.data != "noSubordinate") {
                this.dataForm = res.data.data;
                this.dataForm.orgName = this.fillDeptName;
                this.dataForm.projectCode = this.nodeId;
                this.dataForm.projectName = this.fillDeptName;
                this.headSave();
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                if (res.data.data == "noSubordinate") {
                  this.$confirm("没有下级数据，无需汇总!", {
                    showCancelButton: false,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: "warning"
                  })
                } else {
                  this.$confirm(res.data.data, {
                    showCancelButton: false,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: "warning"
                  })
                }
              }
            }).then(() => {
              return;
            }).finally(() => {
              this.$loading().close();
            })
          } else {
            this.$message.warning("请选择统计时间");
          }
        } else {
          getDetail(this.dataForm).then((res) => {
            if (res.data.code === 200) {
              this.dataForm = res.data.data
              this.query.formDate = res.data.data.statDate
              this.query.affiliationDept = res.data.data.orgCode
              this.query.id = res.data.data.id
              this.dataForm.orgName = res.data.data.projectName
              this.dataState = this.findDictValueByState()
              this.$nextTick(() => {
                if (this.dataForm.dataState == 2 || this.dataForm.dataState == 4) {
                  this.state = true;
                }
              })
              if (this.query.formDate) {
                summaryClick(this.query).then(res => {
                  if (res.data.code === 200 && res.data.data != "有任务未完成不能汇总,详情请通过搜索条件去确认未完成任务." && res.data.data != "noSubordinate") {
                    this.dataForm = res.data.data;
                    this.dataForm.orgName = res.data.data.projectName
                    this.query.id = "";
                    this.headSave();
                    this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                  } else {
                    if (res.data.data == "noSubordinate") {
                      this.$confirm("没有下级数据，无需汇总!", {
                        showCancelButton: false,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: "warning"
                      })
                    } else {
                      this.$confirm(res.data.data, {
                        showCancelButton: false,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: "warning"
                      })
                    }
                  }
                }).then(() => {

                }).finally(() => {
                })
              } else {
                this.$message.warning("请选择统计时间");
              }
            }
          })
        }
      }
    },
    headEdit() {
      this.state = false;
    },
    getByIdData() {
      getDetail(this.dataForm).then((res) => {
        if (res.data.code === 200) {
          this.dataForm = res.data.data
          this.query.formDate = res.data.data.statDate
          this.query.affiliationDept = res.data.data.orgCode
          this.dataForm.orgName = res.data.data.projectName
          this.dataState = this.findDictValueByState()
          this.$nextTick(() => {
            if (this.dataForm.dataState == 2 || this.dataForm.dataState == 4) {
              this.state = true;
            }
          })
          if (this.type === 'workflow') {
            this.onLoad(this.page, {});
          }
        }
      }).finally(() => {
        this.$loading().close()
      })
    },
    headSave() {
      if (this.type !== 'workflow') {
        if (this.nodeId) {
          if (this.query.formDate) {
            if (this.dataForm.dataState == undefined || this.dataForm.dataState == "") {
              this.dataForm.dataState = 1;
            }
            this.dataForm.orgCode = this.nodeId
            //设置组织id和名称
            this.dataForm.statDate = this.query.formDate
            this.dataForm.projectCode = this.nodeId
            this.dataForm.projectName = this.fillDeptName
            this.dataForm.orgName = this.fillDeptName
            this.$nextTick(() => {
              this.$refs.dataForm.validate((valid) => {
                if (valid) {
                  this.$loading();
                  submitSummary(this.dataForm).then(res => {
                    if (res.data.code === 200 && res.data.data != "数据已存在不能重复汇总!") {
                      this.dataForm = res.data.data;
                      if (this.dataForm.dataState == 2 || this.dataForm.dataState == 3) {
                        this.state = true;
                      }
                      this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                    } else {
                      this.$message.warning(res.data.data);
                    }
                    this.getByIdData();
                  })
                    .finally(() => {
                      this.$loading().close();
                    })
                }
              })
            })
          } else {
            this.$message.warning("请选择统计时间");
          }
        } else {
          this.$message.warning("部门编码为空");
        }
      } else {
        // 保存逻辑
        this.$nextTick(() => {
          this.$refs.dataForm.validate((valid) => {
            if (valid) {
              this.$loading();
              submitSummary(this.dataForm).then(res => {
                if (res.data.code === 200 && res.data.data != "数据已存在不能重复汇总!") {
                  this.dataForm = res.data.data;
                  if (this.dataForm.dataState == 2 || this.dataForm.dataState == 3) {
                    this.state = true;
                  }
                  this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
                } else {
                  this.$message.warning(res.data.data);
                }
                this.getByIdData();
              })
                .finally(() => {
                  this.$loading().close();
                })
            }
          })
        })
      }
    },
    headReportView() {
      if (this.nodeId) {
        if (this.query.formDate) {
          // 将字符串转换为 Date 对象
          // const dateObject = new Date(this.query.formDate);
          // // 使用 toISOString 获取 ISO 格式的日期字符串
          // // 然后我们截取前10个字符，即 'YYYY-MM' 部分
          // const formattedDate = dateObject.toISOString().substring(0, 7);
          window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=overseasCode&stat_month=" + this.query.formDate)
        } else {
          window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=overseasCode")
        }
      } else {
        this.$message.warning("请先选择组织")
      }
    },
    initTree() {
      let templateCode = "overseasCode";
      let type = "summary";
      reportTree(templateCode, type)
        .then((result) => {
          this.treeData = result.data.data;
          if (this.treeData == "您不需要填报该报表!") {
            this.$message.warning("您不需要填报该报表!")
            return
          }
          this.node = this.treeData[0];
          if (this.type != 'workflow') {
            this.query.affiliationDept = this.nodeId;
          }
          if (this.currentNodeKey == '') {
            this.$nextTick(() => {
              this.defaultCheckedKeys.push(this.treeData[0].id)
            })
          }
          this.treeLoading = false;
        })
        .catch((err) => {
          this.treeLoading = true;
        });
      if (!this.dataForm.dataState) {
        this.dataForm.dataState = "1"
      }
    },
    rpTaskAttributeSubmit(item) {
      this.dataForm.totalCount = this.totalChineseCnt
      if (!this.dataForm.id) {
        this.dataForm.createUserName = this.userInfo.real_name
      }
      this.dataForm.updateUserName = this.userInfo.real_name
      this.dataForm.projectArea = 2
      this.dataForm.orgCode = this.nodeId
      this.dataForm.dataState = 4;
      submitSummary(item).then((res) => {
        if (res.data.code === 200) {
          this.restRefresh();
          this.getByIdData();
          this.$message({
            message: "操作成功",
            type: "success",
          });
        }
      }).finally(() => {
        this.$loading().close()
      });
      // this.formProcess.id = item.id;
      // this.formProcess.deptId = this.nodeId;
      // this.formProcess.paperName = this.dataForm.paperName;
      // this.handleStartProcess5(true,true).then((res) => {
      //   let processInsId = res.data.data;
      //   item.processInsId = processInsId;
      //   item.dataState = 2;
      //   item.projectArea = 2
      //   item.orgCode = this.nodeId
      //   getProcessDetail({processInsId: processInsId}).then((res) => {
      //     if (res.data.data.flow[0].assigneeIds) {
      //       item.assigneeIds = res.data.data.flow[0].assigneeIds
      //     }
      //     submitSummary(item).then((res) => {
      //       if (res.data.code === 200) {
      //         this.restRefresh();
      //         this.getByIdData();
      //         this.$message({
      //           message: "操作成功",
      //           type: "success",
      //         });
      //       }
      //     })
      //   });
      // });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
    selectionChange(list) {
      this.selectionList = list
    },
    gridHeadEmpty(query) {
      this.onLoad(this.paperPage, query)
    },
    gridHeadSearch(query) {
      this.paperPage.currentPage = 1;
      this.onLoad(this.paperPage, query)
    },
    treeNodeClick(node) {
      this.whether = node.whether
      this.dataForm = [];
      this.state = true
      this.node = node;
      this.nodeId = node.id;
      this.query.unitCode = this.nodeId;
      this.classifyId = this.nodeId;
      this.query.affiliationDept = this.nodeId;
      this.fillDeptName = this.node.deptName
      if (this.query.formDate) {
        this.summaryGetDataList();
      }
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    closeDialog() {
      this.selectionList = [];
      this.paperPage.currentPage = 1;
      this.selectTopicsDialog = false;
    },
    selectionClear() {
      this.selectionList = [];
    },
    rowView(row) {
      this.overseasProjectFilling = true
      this.rowData = {
        type: "view",
        row: JSON.stringify(row),
      }
    },
    rowEdit(row) {
      this.overseasProjectFilling = true
      this.rowData = {
        type: "edit",
        row: JSON.stringify(row),
      }
    },
    headUrging() {
      if (this.selectionList.length == 0) {
        this.$message.warning("请勾选数据！")
        return;
      }
      const hasDataStateNotEqualToTwo = this.selectionList.some(item => item.dataState != "1" && item.dataState != "3");
      if (hasDataStateNotEqualToTwo) {
        this.$message.warning("请确认勾选的数据状态为待提交或驳回！")
      } else {
        this.$loading()
        const result = this.selectionList.map(item => item.orgCode).join(',');
        let data = {deptId: result, reportCode: "overseasCode"}
        reviewAndUrging(data)
          .then((result) => {
            if (result.data.code == 200) {
              this.$loading().close()
              this.onLoad(this.page);
              this.$message.success("催办成功!")
            }
          }).finally(() => {
          this.$loading().close()
        })
      }
    },
    reject() {
      if (this.selectionList.length !== 0) {
        let idList = [];
        this.selectionList.forEach((e) => {
          if (e.dataState != "4") {
            idList.push(e.id)
          }
        });
        if (idList.length === 0) {
          this.$prompt('请输入退回原因:', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputType: "textarea",
            inputPattern: /^[\s\S]{1,200}$/, // 使用正则表达式限制长度在1到200个字符之间
            inputErrorMessage: '请输入1到200个字符的驳回原因', // 当输入不符合正则表达式时显示的错误信息
            closeOnClickModal: false
          }).then(({value}) => {
            let templateCode = "overseasCode"
            this.$loading()
            rejectSendingMessage(this.selectionList, value, templateCode).then((res) => {
              if (res.data.code == 200) {
                this.onLoad(this.page);
                this.$message({
                  type: 'success',
                  message: '退回成功'
                });
              }
            }).catch((e) => {
              this.$message({
                type: 'warning',
                message: '取消退回'
              });
            }).finally(() => {
              this.$loading().close()
            })
          }).catch(() => {
            this.$message({
              type: 'warning',
              message: '取消驳回'
            });
          }).finally(() => {
            this.$loading().close()
          });
        } else {
          return this.$message.warning("请确保选择数据中全部是已通过!")
        }
      } else {
        return this.$message.warning("至少选择一条数据!")
      }
    },
    overseasDialog() {
      this.overseasProjectFilling = false;
      this.onLoad(this.page)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.crudLoading = true;
      summaryGetPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
        this.crudLoading = false;
        this.selectionClear();
      }).catch(() => {
        this.tableData = []
      }).finally(() => {
        this.crudLoading = false;
      });
    },
  },
  mounted() {
    // this.getProcessId()
    if (this.type === 'workflow') {
      this.dataForm.id = this.$route.query.id
      this.getByIdData();
    }
    if (this.type != 'workflow') {
      // this.initTree()
    } else {
      this.treeShow = false
    }
  },
  created() {
    dictionaryBiz("data_status_report").then((res) => {
      this.dataStateDic = res.data.data;
    });
    getDataStatus().then((res) => {
      this.dicData = res.data.data;
    })
    let type = this.$route.query.type;
    let formDate = formatTime(new Date(), "yyyy-MM-dd");
    this.query.formDate = formDate;
    this.type = type
  }
}
</script>

<style lang="scss" scoped>
.custom-grid-layout {
  height: 100%;
}

.head-section {
  margin-bottom: 20px;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

.searchInput {
  width: 300px;
  position: absolute;
  top: 9px;
  right: 255px;
}

.head-picker ::v-deep .el-date-editor.el-input {
  width: 20% !important;
}

//::v-deep .overseasProjectSummary .avue-crud .el-table {
//  height: calc(100vh - 518px) !important;
//  max-height: calc(100vh - 518px) !important;
//}

::v-deep .gridHeadLayout .el-button-group > .el-button:last-child {
  display: none !important;
}

::v-deep .el-form-item__content {
  display: flex;
}

.demo-ruleForm ::v-deep el-date-picker {
  width: 100% !important;
}
</style>
